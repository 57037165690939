// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoolsOfTheChat_container__Xdjp9{
    padding: 30px 100px 50px 100px;
    
    color: #0F1E28;

    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
    }
    
    @media screen and (max-width: 768px) {
        .RoolsOfTheChat_container__Xdjp9 {
          padding:  0 40px 40px 40px;
        }
      }

    /* .main_container{
        display: flex;
        flex-wrap: wrap;
                 
    } */

    
    .RoolsOfTheChat_title__odKP8{
        color:inherit;
        margin: 0;
        padding: 0;
        padding-bottom: 20px;
        font-size: 32px;
        font-weight: 500;
        line-height: 124%;
    }

    .RoolsOfTheChat_list__jeeaQ {
        position: relative;
        margin: -10px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        
      }

     .RoolsOfTheChat_item__CK36R {
        margin: 10px;
        max-width: 400px;

      }

      p{
        margin: 0;
        padding: 0;
    }

    .RoolsOfTheChat_text__TaBKf{
        width: 410px;
        font-weight: 600;

        @media screen and (max-width: 1439px) {
            margin-left: 10px;
            margin-top: 20px;
        }
               

        @media screen and (min-width: 1440px) {
            position: absolute;
            top: 90%;
            right: 0;
        
        }

    }`, "",{"version":3,"sources":["webpack://./src/pages/RoolsOfTheChat/RoolsOfTheChat.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;;IAE9B,cAAc;;IAEd,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB;;IAEA;QACI;UACE,0BAA0B;QAC5B;MACF;;IAEF;;;;OAIG;;;IAGH;QACI,aAAa;QACb,SAAS;QACT,UAAU;QACV,oBAAoB;QACpB,eAAe;QACf,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,kBAAkB;QAClB,aAAa;QACb,UAAU;QACV,aAAa;QACb,eAAe;;;MAGjB;;KAED;QACG,YAAY;QACZ,gBAAgB;;MAElB;;MAEA;QACE,SAAS;QACT,UAAU;IACd;;IAEA;QACI,YAAY;QACZ,gBAAgB;;QAEhB;YACI,iBAAiB;YACjB,gBAAgB;QACpB;;;QAGA;YACI,kBAAkB;YAClB,QAAQ;YACR,QAAQ;;QAEZ;;IAEJ","sourcesContent":[".container{\n    padding: 30px 100px 50px 100px;\n    \n    color: #0F1E28;\n\n    font-family: Manrope;\n    font-size: 20px;\n    font-weight: 400;\n    line-height: 130%;\n    }\n    \n    @media screen and (max-width: 768px) {\n        .container {\n          padding:  0 40px 40px 40px;\n        }\n      }\n\n    /* .main_container{\n        display: flex;\n        flex-wrap: wrap;\n                 \n    } */\n\n    \n    .title{\n        color:inherit;\n        margin: 0;\n        padding: 0;\n        padding-bottom: 20px;\n        font-size: 32px;\n        font-weight: 500;\n        line-height: 124%;\n    }\n\n    .list {\n        position: relative;\n        margin: -10px;\n        padding: 0;\n        display: flex;\n        flex-wrap: wrap;\n\n        \n      }\n\n     .item {\n        margin: 10px;\n        max-width: 400px;\n\n      }\n\n      p{\n        margin: 0;\n        padding: 0;\n    }\n\n    .text{\n        width: 410px;\n        font-weight: 600;\n\n        @media screen and (max-width: 1439px) {\n            margin-left: 10px;\n            margin-top: 20px;\n        }\n               \n\n        @media screen and (min-width: 1440px) {\n            position: absolute;\n            top: 90%;\n            right: 0;\n        \n        }\n\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RoolsOfTheChat_container__Xdjp9`,
	"title": `RoolsOfTheChat_title__odKP8`,
	"list": `RoolsOfTheChat_list__jeeaQ`,
	"item": `RoolsOfTheChat_item__CK36R`,
	"text": `RoolsOfTheChat_text__TaBKf`
};
export default ___CSS_LOADER_EXPORT___;
