// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomModal_modal__mr43c{
    position: fixed;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    padding: 20px;
    height: auto;
    border-radius: 20px;
    border: 2px solid black;
    background-color: #F5FBFF;
    max-height: 85vh;
    overflow-y: auto;
    @media screen and (min-width: 768px) and (max-width: 1023px){
      padding: 30px;
  } 
  @media screen and (min-width: 1024px) {
    padding: 40px;
    }
  
  }

.CustomModal_ReactModal__Content__Wujse {
    max-height: 60vh;
  }

.CustomModal_modal_button__NCMki{
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    margin: 0;
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 50%;
    cursor: pointer;

}

`, "",{"version":3,"sources":["webpack://./src/components/Modal/CustomModal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;IAChB;MACE,aAAa;EACjB;EACA;IACE,aAAa;IACb;;EAEF;;AAEF;IACI,gBAAgB;EAClB;;AAEF;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,UAAU;IACV,SAAS;IACT,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;;AAEnB","sourcesContent":[".modal{\n    position: fixed;\n    top: 50%; \n    left: 50%; \n    transform: translate(-50%, -50%);\n    padding: 20px;\n    height: auto;\n    border-radius: 20px;\n    border: 2px solid black;\n    background-color: #F5FBFF;\n    max-height: 85vh;\n    overflow-y: auto;\n    @media screen and (min-width: 768px) and (max-width: 1023px){\n      padding: 30px;\n  } \n  @media screen and (min-width: 1024px) {\n    padding: 40px;\n    }\n  \n  }\n\n.ReactModal__Content {\n    max-height: 60vh;\n  }\n\n.modal_button{\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    padding: 0;\n    margin: 0;\n    width: 36px;\n    height: 36px;\n    border: none;\n    border-radius: 50%;\n    cursor: pointer;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `CustomModal_modal__mr43c`,
	"ReactModal__Content": `CustomModal_ReactModal__Content__Wujse`,
	"modal_button": `CustomModal_modal_button__NCMki`
};
export default ___CSS_LOADER_EXPORT___;
