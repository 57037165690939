// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_logo__W9EYC{
    @media screen and (max-width: 767px){
        width: 40px;
        height: auto;  
    }
    @media screen and (min-width: 768px) and (max-width: 1023px){
        width: 60px;
        height: auto;
    } 
    @media screen and (min-width: 1024px) {
        width: 80px;
        height: auto;
      }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Logo.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,WAAW;QACX,YAAY;IAChB;IACA;QACI,WAAW;QACX,YAAY;IAChB;IACA;QACI,WAAW;QACX,YAAY;MACd;AACN","sourcesContent":[".logo{\n    @media screen and (max-width: 767px){\n        width: 40px;\n        height: auto;  \n    }\n    @media screen and (min-width: 768px) and (max-width: 1023px){\n        width: 60px;\n        height: auto;\n    } \n    @media screen and (min-width: 1024px) {\n        width: 80px;\n        height: auto;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `Logo_logo__W9EYC`
};
export default ___CSS_LOADER_EXPORT___;
