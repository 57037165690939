// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .resetPasswordForm_input__yj3ir{
    width: 315px;
    margin: 0;
    margin-bottom: 6px;
    height: 50px;
    padding: 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
    box-sizing: border-box;
    border: none;
    border-radius: 15px;
    background: linear-gradient(90deg, rgba(0, 138, 229, 0.3) 0%, rgba(160, 203, 231, 0.1) 100%);
  }

  
  .resetPasswordForm_isInvalid__7oNt2{
    border: 2px solid red;
}
.resetPasswordForm_isValid__B0vZ5{
    border: 2px solid green;
}

.resetPasswordForm_text__SdfBH{
    display: block;
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 8px;
    font-weight: 400;
    color: #4F4F4F;
  ;
  }

  .resetPasswordForm_button__7tjyA {
    font-size: 15px;
   
    width: 200px;
    height: 50px;
    margin-bottom: 6px;
    color: black;
    font-family: Manrope;
    font-weight: 600;
    border: none;
    border-radius: 14px ;
    background: linear-gradient(90deg, rgba(0, 138, 229, 0.45) 0%, rgba(0, 138, 229, 0.25) 50%, rgba(0, 138, 229, 0.45) 100%);
   

    &:hover{
    color: #fff;
    background: linear-gradient(270deg, #20A6FE 0%, #004D94 90%);
    cursor: pointer;
    }
  }

  .resetPasswordForm_errorMessage__bVTTH{
    font-size: 14px;
color: red;
}

.resetPasswordForm_buttonSubmit__CeGYF{
display: flex;
align-items: flex-end;
gap: 130px;
}`, "",{"version":3,"sources":["webpack://./src/components/resetPasswordForm/resetPasswordForm.module.css"],"names":[],"mappings":"EAAE;IACE,YAAY;IACZ,SAAS;IACT,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,4FAA4F;EAC9F;;;EAGA;IACE,qBAAqB;AACzB;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;;EAEhB;;EAEA;IACE,eAAe;;IAEf,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,oBAAoB;IACpB,gBAAgB;IAChB,YAAY;IACZ,oBAAoB;IACpB,yHAAyH;;;IAGzH;IACA,WAAW;IACX,4DAA4D;IAC5D,eAAe;IACf;EACF;;EAEA;IACE,eAAe;AACnB,UAAU;AACV;;AAEA;AACA,aAAa;AACb,qBAAqB;AACrB,UAAU;AACV","sourcesContent":["  .input{\n    width: 315px;\n    margin: 0;\n    margin-bottom: 6px;\n    height: 50px;\n    padding: 10px;\n    font-size: 15px;\n    font-weight: 400;\n    line-height: 1.2;\n    box-sizing: border-box;\n    border: none;\n    border-radius: 15px;\n    background: linear-gradient(90deg, rgba(0, 138, 229, 0.3) 0%, rgba(160, 203, 231, 0.1) 100%);\n  }\n\n  \n  .isInvalid{\n    border: 2px solid red;\n}\n.isValid{\n    border: 2px solid green;\n}\n\n.text{\n    display: block;\n    font-size: 16px;\n    margin-top: 40px;\n    margin-bottom: 8px;\n    font-weight: 400;\n    color: #4F4F4F;\n  ;\n  }\n\n  .button {\n    font-size: 15px;\n   \n    width: 200px;\n    height: 50px;\n    margin-bottom: 6px;\n    color: black;\n    font-family: Manrope;\n    font-weight: 600;\n    border: none;\n    border-radius: 14px ;\n    background: linear-gradient(90deg, rgba(0, 138, 229, 0.45) 0%, rgba(0, 138, 229, 0.25) 50%, rgba(0, 138, 229, 0.45) 100%);\n   \n\n    &:hover{\n    color: #fff;\n    background: linear-gradient(270deg, #20A6FE 0%, #004D94 90%);\n    cursor: pointer;\n    }\n  }\n\n  .errorMessage{\n    font-size: 14px;\ncolor: red;\n}\n\n.buttonSubmit{\ndisplay: flex;\nalign-items: flex-end;\ngap: 130px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `resetPasswordForm_input__yj3ir`,
	"isInvalid": `resetPasswordForm_isInvalid__7oNt2`,
	"isValid": `resetPasswordForm_isValid__B0vZ5`,
	"text": `resetPasswordForm_text__SdfBH`,
	"button": `resetPasswordForm_button__7tjyA`,
	"errorMessage": `resetPasswordForm_errorMessage__bVTTH`,
	"buttonSubmit": `resetPasswordForm_buttonSubmit__CeGYF`
};
export default ___CSS_LOADER_EXPORT___;
