// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageModal_modalContent__30Dbv{
max-width: 80vw;
max-height: 80vh;
}

.ImageModal_imageInModal__Isvx0{
max-width: 70vw;
max-height: 70vh;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/ImageModal.module.css"],"names":[],"mappings":"AAAA;AACA,eAAe;AACf,gBAAgB;AAChB;;AAEA;AACA,eAAe;AACf,gBAAgB;AAChB","sourcesContent":[".modalContent{\nmax-width: 80vw;\nmax-height: 80vh;\n}\n\n.imageInModal{\nmax-width: 70vw;\nmax-height: 70vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `ImageModal_modalContent__30Dbv`,
	"imageInModal": `ImageModal_imageInModal__Isvx0`
};
export default ___CSS_LOADER_EXPORT___;
