// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteRoomFromTabModal_createRoomContainer__gLjFC{
padding: 30px;
}

.DeleteRoomFromTabModal_title__MvLcT {
    text-align: center;
    margin: 0;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 500;
    line-height: 124%;
    
    @media screen and (min-width: 768px) and (max-width: 1023px){
      margin-bottom: 24px;
      font-size: 28px;
  }
 }

 .DeleteRoomFromTabModal_center__3MGBO{
display: flex;
align-items: center;
justify-content: center;
gap: 40px;
}

.DeleteRoomFromTabModal_cancelButton__1UlCV{
    font-size: 24px;
   
    width: 200px;
    height: 60px;
    border: none;
    border-radius: 14px ;
    background: linear-gradient(90deg, rgba(0, 138, 229, 0.45) 0%, rgba(0, 138, 229, 0.25) 50%, rgba(0, 138, 229, 0.45) 100%);
   
    &:hover{
    color: #fff;
    background: linear-gradient(270deg, #20A6FE 0%, #004D94 90%);
    cursor: pointer;
    }
}

.DeleteRoomFromTabModal_confirmButton__o6ZHx{
    color: #fff;
    font-size: 24px;

    width: 200px;
    height: 60px;
    border: none;
    border-radius: 14px ;

    background: linear-gradient(90deg, #E25057 0%, rgba(219, 98, 104, 0.45) 50%, #E25057 100%);

    &:hover{
    color: #000;
    background: linear-gradient(90deg, #53080C 0%, #F1000C 90%);
    cursor: pointer;

    }

}`, "",{"version":3,"sources":["webpack://./src/components/Modal/DeleteRoomFromTabModal.module.css"],"names":[],"mappings":"AAAA;AACA,aAAa;AACb;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;;IAEjB;MACE,mBAAmB;MACnB,eAAe;EACnB;CACD;;CAEA;AACD,aAAa;AACb,mBAAmB;AACnB,uBAAuB;AACvB,SAAS;AACT;;AAEA;IACI,eAAe;;IAEf,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,oBAAoB;IACpB,yHAAyH;;IAEzH;IACA,WAAW;IACX,4DAA4D;IAC5D,eAAe;IACf;AACJ;;AAEA;IACI,WAAW;IACX,eAAe;;IAEf,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,oBAAoB;;IAEpB,0FAA0F;;IAE1F;IACA,WAAW;IACX,2DAA2D;IAC3D,eAAe;;IAEf;;AAEJ","sourcesContent":[".createRoomContainer{\npadding: 30px;\n}\n\n.title {\n    text-align: center;\n    margin: 0;\n    margin-bottom: 24px;\n    font-size: 24px;\n    font-weight: 500;\n    line-height: 124%;\n    \n    @media screen and (min-width: 768px) and (max-width: 1023px){\n      margin-bottom: 24px;\n      font-size: 28px;\n  }\n }\n\n .center{\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ngap: 40px;\n}\n\n.cancelButton{\n    font-size: 24px;\n   \n    width: 200px;\n    height: 60px;\n    border: none;\n    border-radius: 14px ;\n    background: linear-gradient(90deg, rgba(0, 138, 229, 0.45) 0%, rgba(0, 138, 229, 0.25) 50%, rgba(0, 138, 229, 0.45) 100%);\n   \n    &:hover{\n    color: #fff;\n    background: linear-gradient(270deg, #20A6FE 0%, #004D94 90%);\n    cursor: pointer;\n    }\n}\n\n.confirmButton{\n    color: #fff;\n    font-size: 24px;\n\n    width: 200px;\n    height: 60px;\n    border: none;\n    border-radius: 14px ;\n\n    background: linear-gradient(90deg, #E25057 0%, rgba(219, 98, 104, 0.45) 50%, #E25057 100%);\n\n    &:hover{\n    color: #000;\n    background: linear-gradient(90deg, #53080C 0%, #F1000C 90%);\n    cursor: pointer;\n\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createRoomContainer": `DeleteRoomFromTabModal_createRoomContainer__gLjFC`,
	"title": `DeleteRoomFromTabModal_title__MvLcT`,
	"center": `DeleteRoomFromTabModal_center__3MGBO`,
	"cancelButton": `DeleteRoomFromTabModal_cancelButton__1UlCV`,
	"confirmButton": `DeleteRoomFromTabModal_confirmButton__o6ZHx`
};
export default ___CSS_LOADER_EXPORT___;
