// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  ::-webkit-scrollbar {
    background-color: #024a7a;
    width: 20px;
  }
  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: #e2f4ff;
  }
  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: #024a7a;
    border-radius: 2px;
    /* border: 2px solid #fff; */
  }
  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}
/* * {
  border: 1px solid black;
} */

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

.app {
  /* max-width: 1440px; */
  max-width: 2560px;
  margin-left: auto;
  margin-right: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark-theme {
  background-color: #0f1e28;
  color: #fff;
}

.light-theme {
  background-color: #f5fbff;
  color: #000;
}

.ReactModal__Overlay--after-open {
  z-index: 11;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV;IACE,yBAAyB;IACzB,WAAW;EACb;EACA,yDAAyD;EACzD;IACE,yBAAyB;EAC3B;EACA,qBAAqB;EACrB;IACE,yBAAyB;IACzB,kBAAkB;IAClB,4BAA4B;EAC9B;EACA,gDAAgD;EAChD;IACE,aAAa;EACf;AACF;AACA;;GAEG;;AAEH;EACE,SAAS;EACT,kCAAkC;EAClC,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,sBAAsB;EACtB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;aACW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":["* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  ::-webkit-scrollbar {\n    background-color: #024a7a;\n    width: 20px;\n  }\n  /* background of the scrollbar except button or resizer */\n  ::-webkit-scrollbar-track {\n    background-color: #e2f4ff;\n  }\n  /* scrollbar itself */\n  ::-webkit-scrollbar-thumb {\n    background-color: #024a7a;\n    border-radius: 2px;\n    /* border: 2px solid #fff; */\n  }\n  /* set button(top and bottom of the scrollbar) */\n  ::-webkit-scrollbar-button {\n    display: none;\n  }\n}\n/* * {\n  border: 1px solid black;\n} */\n\nbody {\n  margin: 0;\n  font-family: 'Manrope', sans-serif;\n  font-size: 16px;\n  line-height: 1.5;\n  color: #000;\n  background-color: #fff;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nul {\n  list-style: none;\n}\n\n.app {\n  /* max-width: 1440px; */\n  max-width: 2560px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.dark-theme {\n  background-color: #0f1e28;\n  color: #fff;\n}\n\n.light-theme {\n  background-color: #f5fbff;\n  color: #000;\n}\n\n.ReactModal__Overlay--after-open {\n  z-index: 11;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
