// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicy_container__aZqJ4{
    padding: 30px 100px 50px 100px;
    
    color: #0F1E28;

    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
    }
    
    @media screen and (max-width: 768px) {
        .PrivacyPolicy_container__aZqJ4 {
          padding:  0 40px 40px 40px;
        }
      }

    .PrivacyPolicy_main_container__ysw-z{
        display: flex;
        gap: 20px;
         
    }
    
    .PrivacyPolicy_title__T1cpc{
        color:inherit;
        margin: 0;
        padding: 0;
        padding-bottom: 20px;
        font-size: 32px;
        font-weight: 500;
        line-height: 124%;
    }

    .PrivacyPolicy_list__R3OjJ {
        list-style-type: decimal;
        margin: 0;
        padding: 0;
        
      }
      
      .PrivacyPolicy_list__R3OjJ .PrivacyPolicy_item__mXzKa {
        margin-left: 20px;
        margin-bottom: 10px;

      }

      .PrivacyPolicy_imgContainer__EWoic{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* .privacyPolicyImg {
        max-width: 340px;
    }
       */

`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy/PrivacyPolicy.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;;IAE9B,cAAc;;IAEd,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB;;IAEA;QACI;UACE,0BAA0B;QAC5B;MACF;;IAEF;QACI,aAAa;QACb,SAAS;;IAEb;;IAEA;QACI,aAAa;QACb,SAAS;QACT,UAAU;QACV,oBAAoB;QACpB,eAAe;QACf,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,wBAAwB;QACxB,SAAS;QACT,UAAU;;MAEZ;;MAEA;QACE,iBAAiB;QACjB,mBAAmB;;MAErB;;MAEA;QACE,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;IACA;;;QAGI","sourcesContent":[".container{\n    padding: 30px 100px 50px 100px;\n    \n    color: #0F1E28;\n\n    font-family: Manrope;\n    font-size: 20px;\n    font-weight: 400;\n    line-height: 130%;\n    }\n    \n    @media screen and (max-width: 768px) {\n        .container {\n          padding:  0 40px 40px 40px;\n        }\n      }\n\n    .main_container{\n        display: flex;\n        gap: 20px;\n         \n    }\n    \n    .title{\n        color:inherit;\n        margin: 0;\n        padding: 0;\n        padding-bottom: 20px;\n        font-size: 32px;\n        font-weight: 500;\n        line-height: 124%;\n    }\n\n    .list {\n        list-style-type: decimal;\n        margin: 0;\n        padding: 0;\n        \n      }\n      \n      .list .item {\n        margin-left: 20px;\n        margin-bottom: 10px;\n\n      }\n\n      .imgContainer{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n    /* .privacyPolicyImg {\n        max-width: 340px;\n    }\n       */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PrivacyPolicy_container__aZqJ4`,
	"main_container": `PrivacyPolicy_main_container__ysw-z`,
	"title": `PrivacyPolicy_title__T1cpc`,
	"list": `PrivacyPolicy_list__R3OjJ`,
	"item": `PrivacyPolicy_item__mXzKa`,
	"imgContainer": `PrivacyPolicy_imgContainer__EWoic`
};
export default ___CSS_LOADER_EXPORT___;
