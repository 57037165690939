// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchPage_searchPageContainer__TQPfB {
    padding: 20px;
  }

  .SearchPage_container__\\+ipzK{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
      /* border: 1px solid #20A6FE; */
     
  
    

  }
  
  .SearchPage_resultSection__4YYMK {
    /* max-width: 1000px;
    margin-left: auto;
    margin-right: auto; */
    margin-bottom: 20px;
    padding: 25px 50px 50px;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(10, 132, 180, 0.25);
  }
  
  .SearchPage_resultItem__42asL {
    display: flex;
    align-items: center;
    height: 80px;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #0287DF -90%, rgba(2, 135, 223, 0) 67.98%);
    cursor: pointer;
    border-radius: 10px;

    
  }
  
  .SearchPage_resultAvatar__9BxLc {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin-right: 50px;
  }
  
  .SearchPage_resultTitle__rWnry{
  font-family: Manrope;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 10px;
  }

  .SearchPage_itemName__2SeNk{
    font-size: 14px;
  }

  .SearchPage_itemSvg__5wTOR{
    margin-left: auto;
    width: 35px;
    height: 25px;
    fill: #134563;
  }

  .SearchPage_itemSvg__5wTOR:hover{
    fill: #20A6FE;
  }`, "",{"version":3,"sources":["webpack://./src/pages/SearchPage/SearchPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;MACX,+BAA+B;;;;;EAKnC;;EAEA;IACE;;yBAEqB;IACrB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,iDAAiD;EACnD;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,6EAA6E;IAC7E,eAAe;IACf,mBAAmB;;;EAGrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;EACA,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,aAAa;EACf","sourcesContent":[".searchPageContainer {\n    padding: 20px;\n  }\n\n  .container{\n    max-width: 1000px;\n    margin-left: auto;\n    margin-right: auto;\n    padding: 40px;\n      /* border: 1px solid #20A6FE; */\n     \n  \n    \n\n  }\n  \n  .resultSection {\n    /* max-width: 1000px;\n    margin-left: auto;\n    margin-right: auto; */\n    margin-bottom: 20px;\n    padding: 25px 50px 50px;\n    border-radius: 20px;\n    box-shadow: 0px 4px 10px rgba(10, 132, 180, 0.25);\n  }\n  \n  .resultItem {\n    display: flex;\n    align-items: center;\n    height: 80px;\n    margin-bottom: 10px;\n    background: linear-gradient(90deg, #0287DF -90%, rgba(2, 135, 223, 0) 67.98%);\n    cursor: pointer;\n    border-radius: 10px;\n\n    \n  }\n  \n  .resultAvatar {\n    width: 80px;\n    height: 80px;\n    border-radius: 10px;\n    margin-right: 50px;\n  }\n  \n  .resultTitle{\n  font-family: Manrope;\n  font-size: 32px;\n  font-weight: 500;\n  line-height: 1.2;\n  margin-bottom: 10px;\n  }\n\n  .itemName{\n    font-size: 14px;\n  }\n\n  .itemSvg{\n    margin-left: auto;\n    width: 35px;\n    height: 25px;\n    fill: #134563;\n  }\n\n  .itemSvg:hover{\n    fill: #20A6FE;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchPageContainer": `SearchPage_searchPageContainer__TQPfB`,
	"container": `SearchPage_container__+ipzK`,
	"resultSection": `SearchPage_resultSection__4YYMK`,
	"resultItem": `SearchPage_resultItem__42asL`,
	"resultAvatar": `SearchPage_resultAvatar__9BxLc`,
	"resultTitle": `SearchPage_resultTitle__rWnry`,
	"itemName": `SearchPage_itemName__2SeNk`,
	"itemSvg": `SearchPage_itemSvg__5wTOR`
};
export default ___CSS_LOADER_EXPORT___;
