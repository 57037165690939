import React from 'react';
import CustomModal from './CustomModal';
import css from './PasswordChangeSuccesfulModal.module.css';


const PasswordChangeSuccesfulModal = ({ isOpen, onClose }) => {

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className={css.modal}>
      <div className={css.container}>     
          <h2 className={css.title}>Password update succesfully</h2>
          <button className={css.button} onClick={onClose}>Close</button>
        </div>
    </CustomModal>
  );
};

export default PasswordChangeSuccesfulModal;
